<template>
  <div :style="state.styleObj"></div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "TextDivider",
  props: {
    width: {
      type: String,
      default: "1px",
    },
    height: {
      type: String,
      default: "14px",
    },
    color: {
      type: String,
      default: "#5A5B5F",
    },
    margin: {
      type: String,
      default: "0 8px",
    },
  },
  setup(props) {
    const state = reactive({
      styleObj: {
        width: props.width,
        height: props.height,
        background: props.color,
        margin: props.margin,
      },
    });

    return { state };
  },
};
</script>
